import Head from 'next/head'
import PropTypes from 'prop-types'

import { HOME_SHOP_QUERY } from '@sholdi/graphql/queries/admin/homeContent'
import CATEGORIES_QUERY from '@sholdi/graphql/queries/categories/categories'
import BASIC_PRODUCTS_QUERY from '@sholdi/graphql/queries/products/products'
import SHOPS_QUERY from '@sholdi/graphql/queries/shop/shops'

import useSettings from '@sholdi/hooks/useSettings'

import categoryTransformer from '@sholdi/shared/lib/transformers/category'
import transformer from '@sholdi/shared/lib/transformers/product'
// import faqTransfromer from '@sholdi/shared/lib/transformers/faqs'
import shopTransformer from '@sholdi/shared/lib/transformers/shop'
import initUrql from '@sholdi/shared/lib/urql/init-urql'

import {
  computeGridItemStyles,
  gridObjectToTailwindClasses,
} from '@sholdi/ui/molecules/GridLayout/helpers'
import Carousel from '@sholdi/ui/organisms/Carousel'
import Faqs from '@sholdi/ui/organisms/Faqs'

import Metadata from '@sholdi/primitives/atoms/Metadata'

import Home from '../components/pageviews/Home'
import MainLayout from '../components/templates/MainLayout'
import { withServerSideProps } from '../lib/withServerSideProps'
import getEnv from '@sholdi/shared/lib/env/getEnv'

// index page

const IndexPage = ({ slides = [], faqs, ...props }) => {
  const { appUrl, shopName, description, logo } = useSettings()
  return (
    <>
      <Head>
        {Metadata({
          metaTitle: 'Početna',
          metaDescription:
            description ?? `Pridružite se ${shopName} platformi i olakšajte sebi posao.`,
          canonical: appUrl,
          image: logo,
          url: appUrl,
        })}

        <meta httpEquiv="content-language" content="bs-BA" />
        <title>Početna | {shopName}</title>
      </Head>
      <MainLayout
        subheader={slides.length ? <Carousel slides={slides ?? []} /> : null}
        subFooter={<Faqs type="pricing" faqs={faqs} className="bg-primary-foreground" />}
        fluid
      >
        <Home {...props} />
      </MainLayout>
    </>
  )
}

IndexPage.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})),
  faqs: PropTypes.arrayOf(PropTypes.shape({})),
}

export const getServerSideProps = withServerSideProps({
  getProps: async context => {
    const { res } = context
    res.setHeader(
      'Cache-Control',
      'public, max-age=30, s-maxage=30 stale-while-revalidate=86400',
    )

    const { client } = initUrql('public', context)

    const { data, error } = await client
      .query(HOME_SHOP_QUERY, {
        shopId: getEnv('NEXT_PUBLIC_SHOLDI_STORE_ID'),
      })
      .toPromise()
    const { settingsMarketplaceSettings, sliders = [], faqs = [] } = data || {}

    const sections = settingsMarketplaceSettings?.[0]?.content?.variants?.default ?? []

    let pageData = {
      slides: sliders?.[0]?.sliderItems ?? [],
      sections,
      faqs,
    }

    for await (let section of sections) {
      if (section.type === 'gridBanner') {
        pageData = {
          ...pageData,
          [`classNames_${section.sort}`]: Array(section.banners.length)
            .fill(0)
            .map((_, index) => {
              const itemProps = computeGridItemStyles(section.layouts, index)
              const gridClasses = gridObjectToTailwindClasses(itemProps)
              return gridClasses
            }),
        }
      }
      if (section.type === 'product') {
        const { data } = await client
          .query(BASIC_PRODUCTS_QUERY, {
            where: {
              id: {
                _in: section.banners.map(product => product.id),
              },
            },
          })
          .toPromise()
        pageData = {
          ...pageData,
          [`products_${section.sort}`]: data?.products.map(transformer),
        }
      }
      if (section.type === 'category') {
        const { data = { categories: [] } } = await client
          .query(CATEGORIES_QUERY, {
            where: {
              id: { _in: section.banners.map(category => category.id) },
            },
          })
          .toPromise()
        pageData = {
          ...pageData,
          [`categories_${section.sort}`]: data?.categories
            .map(categoryTransformer)
            .map(category => ({
              ...category,
              image:
                section.banners.find(banner => banner.id == category.id)?.imagePath ??
                category.image,
            })),
        }
      }
      if (section.type === 'shopCard') {
        const { data = { shops: [] } } = await client
          .query(SHOPS_QUERY, {
            where: {
              id: { _in: section.banners.map(shop => shop.id) },
            },
          })
          .toPromise()
        pageData = {
          ...pageData,
          [`shops_${section.sort}`]: data?.shops.map(shopTransformer),
        }
      }
    }

    if (!error) {
      return pageData
    }

    return {
      sections: [],
      slides: [],
    }
  },
})

export default IndexPage
