import PropTypes from 'prop-types'

import PageBanners from '@sholdi/ui/organisms/PageBanners'

const Home = props => <PageBanners {...props} />

Home.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      sort: PropTypes.number,
    }),
  ),
}

export default Home
