import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import dynamic from "next/dynamic";

import useTranslation from "@sholdi/hooks/useTranslation";

import Text from "@sholdi/primitives/atoms/Text";
import Box from "@sholdi/primitives/atoms/Box";

const Accordion = dynamic(() => import("../../molecules/Accordion"));

const Faqs = ({ faqs = [], type = "shop", className, ...props }) => {
  const { t } = useTranslation();

  if (!faqs.length) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "w-full flex items-center flex-col py-15 px-2 md:px-0",
        className,
      )}
      {...props}
    >
      <Text className="font-semibold font-heading text-4xl md:text-4xl leading-10 text-center">
        {t("faqs")}
      </Text>
      <Text
        className={twMerge(
          "font-body text-xl md:text-xl leading-7 text-center mt-5",
          type === "shop" ? "text-black" : "mix-blend-darken",
          type === "shop" ? "mb-12" : "mb-18",
        )}
      >
        {t("featuredQuestions")}
      </Text>
      {faqs.map((faq) => (
        <Box key={faq.id} className="w-full mb-8">
          <Accordion
            title={faq.title}
            iconType="plus"
            iconColor={type === "pricing" && "primary.main"}
            variant={type === "shop" ? "primary" : "pricing"}
            activatorProps={{
              className: "w-full border-b border-b-current py-4",
            }}
            className="w-full lg:w-2/3 mx-auto shadow-none"
          >
            <Text className="mt-0 text-current p-4">{faq.body}</Text>
          </Accordion>
        </Box>
      ))}
    </div>
  );
};

Faqs.displayName = "Faqs";

Faqs.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  className: PropTypes.string,
};

export default Faqs;
