import PropTypes from 'prop-types'
import { useKeenSlider } from 'keen-slider/react'

import ChevronLeft from '@sholdi/icons/ChevronLeft'
import ChevronRight from '@sholdi/icons/ChevronRight'
import Box from '@sholdi/primitives/atoms/Box'
import IconButton from '@sholdi/primitives/atoms/IconButton'
import Slide from '../../molecules/Slide'
import Container from '../../molecules/Container'

const Carousel = ({ slides }) => {
  // Keen slider depends on js loading properly,
  // before use effect executes confirming js has loaded
  // keen slider is replaced by a dummy placeholder slide
  // to prevent FOUC

  const moreThanOneSlide = slides.length > 1

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: moreThanOneSlide,
      slidesPerView: 1,

      // defaultAnimation: { duration: 2000 },
    },
    [
      slider => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider?.next()
          }, 5000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ],
  )

  return (
    <>
      <div className="w-full bg-primary-lightest">
        <Container fluid>
          <div className="flex flex-row justify-between w-full">
            {/* {instanceRef.current && ( */}
            {moreThanOneSlide && (
              <div className="hidden lg:flex items-center">
                <IconButton
                  title="prev-icon"
                  onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                  className="hidden xl:block size-10"
                >
                  <ChevronLeft strokeWidth="1" className={'!size-10'} />
                </IconButton>
              </div>
            )}
            {/* )} */}
            <Box
              ref={sliderRef}
              className="keen-slider w-full flex flex-row overflow-hidden"
            >
              {slides.map((slideItem, i) => (
                <Slide
                  className="keen-slider__slide"
                  key={slideItem.id}
                  priority={i === 0}
                  {...slideItem}
                />
              ))}
            </Box>
            {/* {instanceRef.current && ( */}
            {moreThanOneSlide && (
              <div className="hidden lg:flex items-center">
                <IconButton
                  onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                  title="next-icon"
                  className="hidden lg:block size-10"
                >
                  <ChevronRight strokeWidth="1" className={'!size-10'} />
                </IconButton>
              </div>
            )}
            {/* )} */}
          </div>
        </Container>
      </div>
    </>
  )
}

Carousel.displayName = 'Carousel'

Carousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.shape({})]),
  ),
}

export default Carousel
